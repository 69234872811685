import { IconType } from '../types/types';
import Icon from './Icons/Icon';

type IconBlobProps = {
  icon?: IconType;
  header: string;
  text: string;
};

const IconBlob: React.FC<IconBlobProps> = ({ icon, header, text }) => {
  return (
    <div className='w-72 flex items-center flex-col p-4'>
      <div className='flex'>
        <Icon iconType={icon} iconClassName='h-12 w-12  text-green-600' />
      </div>
      <div className='text-lg font-medium uppercase'>{header}</div>
      <div className='pt-4 text-base'>{text}</div>
    </div>
  );
};

export default IconBlob;
