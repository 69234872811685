import { Link } from 'react-router-dom';
import Icon from './Icons/Icon';

type SplashProps = {
  className?: string;
  header?: string;
  text?: string;
  link?: string;
  linkText?: string;
};

const Splash: React.FC<SplashProps> = ({
  className,
  header,
  text,
  link,
  linkText,
}) => {
  return (
    <div className={className}>
      <div className='md:text-2xl sm:text-xl text-xs flex justify-center text-shadow'>
        {text}
      </div>
      <div className='md:text-7xl sm:text-5xl text-3xl flex justify-center text-shadow'>
        {header}
      </div>{' '}
      {link && (
        <div className='flex justify-center md:mt-14 mt-10'>
          <Link
            to={link}
            className='flex items-center hover:bg-opacity-30 hover:bg-gray-400 pl-3 p-1 rounded-sm text-shadow'
          >
            <div className='flex items-center text-shadow'>{linkText}</div>
            <div className='flex items-center'>
              <Icon iconClassName='h-5 w-5' iconType='ChevronRight' />
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Splash;
