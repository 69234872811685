type loaderProps = {
  bgColor: string;
  size?: string;
};

const Loader: React.FC<loaderProps> = ({
  bgColor = 'bg-gray-500',
  size = 'm',
}) => {
  const circleSize = (size: string) => {
    switch (size) {
      case 's':
        return 'h-2.5 w-2.5';
      case 'm':
        return 'h-5 w-5';
      case 'l':
        return 'h-10 w-10';
    }
  };

  let circleCommonClasses = `${bgColor} ${circleSize(size)} rounded-full`;

  return (
    <div className='flex'>
      <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
      <div className={`${circleCommonClasses} animate-bounce400`}></div>
    </div>
  );
};

export default Loader;
