import CenterTextSplash from '../components/centerTextSplash';
import DoubleSplash from '../components/doublesplash';
import IconBlob from '../components/iconBlob';
import Splash from '../components/splash';

const blobs = [
  {
    header: 'Erfarenhet',
    text: 'Vi har decenniers erfarenhet av att hjälpa våra klienter med stort som smått.',
  },
  {
    header: 'Klientfokus',
    text: 'Våra klienter är det viktigaste vi har. Så enkelt är det.',
  },
  {
    header: 'Lokal förankring',
    text: 'Vårt fokus är Västsverige. Vi har kontor i Trollhättan och Göteborg.',
  },
  {
    header: 'Internationellt',
    text: 'Vi har biträtt såväl i det lokala näringslivet som i globala affärer.',
  },
];

const SmallTexts = () => {
  return (
    <div className='w-full flex sm:flex-row flex-col sm:items-start items-center p-4 justify-center mb-10'>
      {blobs.map((blob, index) => {
        return (
          <IconBlob
            key={`${blob.header}-${index}`}
            text={blob.text}
            header={blob.header}
            icon='Check'
          />
        );
      })}
    </div>
  );
};

const StartPage = () => {
  return (
    <div className='text-lg'>
      <Splash
        className='w-full sm:h-144 h-72 pt-24 bg-desk-pattern bg-cover text-white'
        header='Västsverige i fokus'
        text='Erfarna advokater med lokal förankring'
        link='/medarbetare'
        linkText='Hitta den du söker'
      />

      <CenterTextSplash
        header='Rådgivning och stöd'
        className='flex items-center justify-center mt-20'
        link='/kontakt'
        linkText='Kontakta oss'
        topTextBlock='Behöver du hjälp av en offentlig försvarare eller ett målsägandebiträde när du blivit utsatt för brott? Har du frågor kring tvångsmål? Vi kan hjälpa dig.'
        bottomTextBlock='Vi kan även ge dig och ditt bolag rådgivning om era affärer eller hjälpa till när ditt bolag köper ett annat.'
      />
      <DoubleSplash
        className='mt-20 flex justify-center'
        leftSplashHeader='Våra kontor'
        leftSplashSubHeader='Besök oss gärna'
        leftSplashText='Vårt kontor finns i Trollhättan på Storgatan 50. Vi har även mottagningskontor på andra orter i Västsverige.'
        rightSplashHeader='I behov av juridisk expertis?'
        rightSplashSubHeader='Vi har det som krävs'
        rightSplashText='Vi är advokater som är starkt förankrade i Västsverige. Vi har omfattande erfarenhet av b la brottmål
samt från affärslivet i Stockholm, Göteborg och Trestadsområdet.'
      />

      <CenterTextSplash
        header='Hitta till oss'
        className='flex items-center justify-center mt-20'
        link='/hitta-hit'
        linkText='Hitta hit'
        topTextBlock=''
        bottomTextBlock='Besök oss gärna på kontoret. våra ordinarie öppetider är vardagar från klockan 09:00 till 16:00.'
      />
      <SmallTexts />
    </div>
  );
};

export default StartPage;
