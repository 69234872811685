import { useState } from 'react';



type businessCardProps = {
  name?: string;
  title?: string;
  mail?: string;
  tel?: string;
  image?: string;
  initials?: string;
};

const BusinessCard: React.FC<businessCardProps> = ({
  name,
  title,
  mail,
  tel,
  image,
  initials,
}) => { 
  const [hideImage, setHideImage] = useState(false);

  return (
    <div className=' w-80  h-48 sm:w-96 sm:h-52 border bg-gray-600 text-gray-50 shadow grid grid-flow-col grid-cols-5 m-2'>
      <div className='flex items-center justify-center col-span-2'>
        {
        hideImage ? (
          <div className='rounded-full h-28 w-28 flex items-center justify-center border bg-gradient-to-t from-gray-200 via-gray-100 to-gray-200'>
            <p className='text-5xl text-gray-600'>{initials}</p>
          </div>
        ) : 
        (
          <img
            src={image}
            className='px-2 ml-2'
            alt={initials}
            onError={() => {
              setHideImage(true);
            }}
          ></img>
          // <img
          //   src={image}
          //   className='rounded-full h-28 w-28 sm:h-32 sm:w-32 border'
          //   alt={initials}
          //   onError={() => {
          //     setHideImage(true);
          //   }}
          // ></img>
        )}
      </div>
      <div className='flex flex-col justify-center ml-2 col-span-3'>
        <div className='w-f border-b'>
          <p className='text-xs font-medium'>{title}</p>
        </div>
        <p className='text-sm'>{name}</p>
        <div>
          <a className='hover:text-gray-400 text-xs' href={`tel:${tel}`}>
            {tel}
          </a>
        </div>
        <div>
          <a className='hover:text-gray-400 text-xs' href={`mailto:${mail}`}>
            {mail}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
