type DoubleSplashProps = {
  className?: string;
  leftSplashImage?: string;
  leftSplashHeader: string;
  leftSplashSubHeader?: string;
  leftSplashText?: string;
  rightSplashImage?: string;
  rightSplashHeader: string;
  rightSplashSubHeader?: string;
  rightSplashText?: string;
};

const DoubleSplash: React.FC<DoubleSplashProps> = ({
  className,
  leftSplashHeader,
  leftSplashImage,
  leftSplashSubHeader,
  leftSplashText,
  rightSplashHeader,
  rightSplashImage,
  rightSplashSubHeader,
  rightSplashText,
}) => {
  return (
    <div className={className}>
      <div className='flex-grow grid md:grid-cols-2 grid-cols-1 gap-2 lg:mx-20 mx-4 max-w-5xl text-shadow'>
        <div className='flex flex-col flex-grow md:h-104 h-96 p-4 m-2 border text-white bg-nilsson-pattern lg:bg-fixed bg-cover'>
          <div className='flex justify-center mt-10 text-2xl'>
            {leftSplashSubHeader}
          </div>
          <div className='flex justify-center mt-4 text-4xl font-bold font-serif'>
            {leftSplashHeader}
          </div>
          <div className='flex flex-grow justify-center mt-5 text-base'>
            {leftSplashText}
          </div>
        </div>
        <div className='flex flex-col flex-grow md:h-104 h-96 p-4 m-2 border text-white bg-lawbook-pattern lg:bg-fixed bg-cover'>
          <div className='flex justify-center mt-10 text-2xl'>
            {rightSplashSubHeader}
          </div>
          <div className='flex justify-center mt-4 text-4xl font-bold font-serif'>
            {rightSplashHeader}
          </div>
          <div className='flex flex-grow justify-center mt-5 text-base'>
            {rightSplashText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleSplash;
