import { useEffect, useState } from 'react';
import db from '../firebase/firebase.config';

interface Data {
  error: Error | null;
  loading: boolean;
  workers: Worker[];
}

interface Worker {
  namn?: string;
  title?: string;
  mail?: string;
  tel?: string;
  image?: string;
  initials?: string;
}

const useWorkers = () => {
  const [data, setData] = useState<Data>({
    error: null,
    loading: true,
    workers: [],
  });

  useEffect(() => {
    const unsubscribe = db.collection('workers').onSnapshot(
      (snapshot) => {
        setData({
          error: null,
          loading: false,
          workers: snapshot.docs
            .map((doc) => ({
              namn: doc.data().name,
              title: doc.data().title,
              mail: doc.data().mail,
              tel: doc.data().tel,
              image: doc.data().image,
              initials: doc.data().initials,
            }))
            .sort((a, b) => (a.namn >= b.namn ? 1 : -1)),
        });
      },
      (error) => {
        setData({
          error,
          loading: false,
          workers: [],
        });
      }
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};

export default useWorkers;
