export type IconType =
  | 'academic-cap'
  | 'arrow-up'
  | 'hamburger'
  | 'ChevronRight'
  | 'ChevronLeft'
  | 'ChevronUp'
  | 'ChevronDown'
  | 'MenuAlt1'
  | 'MenuAlt2'
  | 'MenuAlt3'
  | 'MenuAlt4'
  | 'Email'
  | 'Phone'
  | 'Close'
  | 'Check';

// export type ContactLinkType = 'email' | 'phone';
export enum ContactLinkType {
  Email = 'email',
  Phone = 'phone',
}

export type Workers = {
  name: string;
  titel: string;
  mail?: string;
  tel?: string;
  image?: string;
};
