import CookieConsent from 'react-cookie-consent';

type CoockieAgreementProps = {
  header: string;
  text: string;
  buttonText: string;
  cookieName: string;
  expire: number;
};

const CookieAgreement: React.FC<CoockieAgreementProps> = ({
  header,
  text,
  buttonText,
  cookieName,
  expire,
}) => {
  return (
    <CookieConsent
      overlay={true}
      location='bottom'
      buttonText={buttonText}
      cookieName={cookieName}
      style={{ background: '#2B373B' }}
      buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      expires={expire}
    >
      <span>{header}</span>
      <br />
      <span style={{ fontSize: '10px' }}>{text}</span>
    </CookieConsent>
  );
};
export default CookieAgreement;
