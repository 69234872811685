import './App.css';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';

//import firebase, { db } from './firebase/index';
//import { getDatabase, ref, onValue } from 'firebase/database';

import Header from './components/header';
import StartPage from './layout/Start';
import AboutUs from './layout/AboutUs';
import Contact from './layout/Contact';
import FindUs from './layout/FindUs';
import Footer from './components/footer';
import { useEffect } from 'react';
import CookieAgreement from './components/cookieAgreement';

type Props = { children?: React.ReactNode };

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const routePath = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [routePath]);

  return <>{children}</>;
};

function App() {
  return (
    <div className='App font-serif'>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Route path='/' exact component={StartPage} />
        <Route path='/medarbetare' component={AboutUs} />
        <Route path='/hitta-hit' component={FindUs} />
        <Route path='/kontakt' component={Contact} />
        <Footer />
      </BrowserRouter>
      <CookieAgreement
        header='Denna webbplats använder cookies'
        text='Vi använder enhetsidentifierare för att anpassa innehållet till användarna, tillhandahålla funktioner för sociala medier, och analysera vår trafik. Vi vidarebefodraräven sådana identifierare och annan information från din enhet till de sociala medier och annons- och analysföretag som vi samarbetar med. Dessa kan i sin tur kombinera informationen men annan information som du har tillhandahållit eller som de har samlat in när du använt deras tjänster.'
        buttonText='Jag accepterar'
        expire={20}
        cookieName='nilssonLawCookieTest'
      ></CookieAgreement>
    </div>
  );
}

export default App;
