import BusinessCard from '../components/businessCard';
import Loader from '../components/loader';
//import PersonalCard from '../components/personalCard';
import useWorkers from '../firebase/useWorkers';

const AboutUs = () => {
  const { error, loading, workers } = useWorkers();
  const loadingError: boolean = error !== null;

  const Error = () => {
    return (
      <div className='m-8'>
        <p>
          Kontaktinformation kan tyvärr inte hämtas. Försök igen om en liten
          stund.
        </p>
        <p>
          Ni kan nå kontoret via telefon,<b> 0520-48 00 01</b>
        </p>
      </div>
    );
  };

  const Cards = () => {
    return (
      <div className='min-h-screen'>
        <div className='w-full sm:h-144 h-96 pt-24 bg-nilsson-pattern bg-cover text-white p-10 text-shadow'>
          <h2 className='pt-5 sm:pt-12 md:pt-28 sm:pl-28 text-3xl sm:text-5xl '>
            Medarbetare
          </h2>
          <p className='sm:pl-28 text-sm sm:text-xl max-w-lg'>
            Kontakta oss gärna
          </p>
        </div>
        <div className='flex flex-wrap sm:flex-row flex-col items-center md:justify-start m-2 sm:p-10'>
          {loading ? (
            <div className='mt-10'>
              <Loader bgColor='bg-green-300' size='s' />
            </div>
          ) : (
            workers
              //.sort((a, b) => (a.titel >= b.titel ? 1 : -1))
              .map((item, index) => {
                return (
                  <div key={`${item.mail}-${index}`}>
                    {/*  <PersonalCard
                    key={index}
                    name={item.namn}
                    titel={item.title}
                    mail={item.mail}
                    tel={item.tel}
                    image={item.image}
                    initials={item.initials}
                  />*/}

                    <BusinessCard
                      name={item.namn}
                      title={item.title}
                      mail={item.mail}
                      tel={item.tel}
                      image={item.image}
                      initials={item.initials}
                    ></BusinessCard>
                  </div>
                );
              })
          )}
        </div>
      </div>
    );
  };

  return <>{loadingError ? <Error /> : <Cards />}</>;
};

export default AboutUs;
