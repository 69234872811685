import { ContactLinkType, IconType } from '../types/types';
import Icon from './Icons/Icon';

type ContactCardProps = {
  header?: string;
  text: string;
  icon?: IconType;
  linkType: ContactLinkType;
};

const ContactCard: React.FC<ContactCardProps> = ({
  header,
  text,
  icon,
  linkType,
}) => {
  return (
    <div className='border max-w-screen-sm h-72 sm:w-104 flex flex-col justify-center items-center text-center shadow-xl m-2 sm:m-8 mb-4 sm:mb-4 pb-4'>
      <div className=''>
        <Icon iconType={icon} iconClassName='h-20 text-gray-600' />
      </div>
      <span className='text-xl p-2'>{header}</span>
      <a
        className='text-blue-400'
        href={
          ContactLinkType.Email === linkType ? `mailto:${text}` : `tel:${text}`
        }
      >
        {text}
      </a>
    </div>
  );
};

export default ContactCard;
