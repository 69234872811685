import * as HeroIcons from '@heroicons/react/solid';
import { IconType } from '../../types/types';

//type IconType = 'academic-cap' | 'arrow-up' | 'hamburger';
type HeroIconProps = {
  iconType?: IconType;
  iconClassName?: string;
};

const HeroIcon: React.FC<HeroIconProps> = ({
  iconType = 'academic-cap',
  iconClassName = 'h-10 w-10 text-blue-500',
}) => {
  //const test = `<HeroIcon.${iconType} className='h-10 w-10 text-blue-500' />`;

  switch (iconType) {
    case 'academic-cap':
      return <HeroIcons.AcademicCapIcon className={iconClassName} />;
    case 'arrow-up':
      return <HeroIcons.ArrowUpIcon className={iconClassName} />;
    case 'hamburger':
      return <HeroIcons.MenuIcon className={iconClassName} />;
    case 'ChevronRight':
      return <HeroIcons.ChevronRightIcon className={iconClassName} />;
    case 'ChevronLeft':
      return <HeroIcons.ChevronLeftIcon className={iconClassName} />;
    case 'ChevronUp':
      return <HeroIcons.ChevronUpIcon className={iconClassName} />;
    case 'ChevronDown':
      return <HeroIcons.ChevronDownIcon className={iconClassName} />;
    case 'MenuAlt1':
      return <HeroIcons.MenuAlt1Icon className={iconClassName} />;
    case 'MenuAlt2':
      return <HeroIcons.MenuAlt2Icon className={iconClassName} />;
    case 'MenuAlt3':
      return <HeroIcons.MenuAlt3Icon className={iconClassName} />;
    case 'MenuAlt4':
      return <HeroIcons.MenuAlt4Icon className={iconClassName} />;
    case 'Email':
      return <HeroIcons.MailIcon className={iconClassName} />;
    case 'Phone':
      return <HeroIcons.PhoneIcon className={iconClassName} />;
    case 'Close':
      return <HeroIcons.XIcon className={iconClassName} />;
    case 'Check':
      return <HeroIcons.CheckIcon className={iconClassName} />;

    default:
      return <HeroIcons.QuestionMarkCircleIcon className={iconClassName} />;
  }
};

export default HeroIcon;
