const FindUs = () => {
  return (
    <div>
      <div className='w-full sm:h-144 h-96'>
        {/* TODO: fix loader på ngt sätt... */}
        <div id='map_canvas w-full sm:h-144'>
          <iframe
            title='map'
            className='w-full sm:h-144 h-96'
            id='gmap_canvas'
            src='https://maps.google.com/maps?q=Trollh%C3%A4ttan,%20storgatan%2050&t=&z=15&ie=UTF8&iwloc=&output=embed'
            scrolling='no'
          ></iframe>
        </div>
      </div>
      <div className='h-64 flex justify-center mt-11'>
        <div className='grid grid-cols-2 gap-1 leading-5'>
          <div>
            <p>Nilsson Advokater</p>
            <p>Storgatan 50</p>
            <p>461 30</p>
            <p>Trollhättan</p>
          </div>
          <div>
            <p>Tel: 0520-48 00 01</p>
            <p>E-post: info@nilssonlaw.se</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindUs;
