import ContactCard from '../components/contactCard';
import { ContactLinkType } from '../types/types';

const Contact = () => {
  return (
    <div className='min-h-screen'>
      <div className='w-full sm:h-144 h-72 pt-24 bg-notes-pattern bg-cover text-white p-10 text-shadow'>
        <h2 className='pt-5 sm:pt-12 md:pt-28 sm:pl-28 text-3xl sm:text-5xl'>
          Kontakta oss
        </h2>
        <p className='sm:pl-28 text-sm sm:text-xl max-w-lg'>
          Om du behöver juridisk rådgivning så får du gärna kontakta oss.
        </p>
      </div>
      <div className='flex sm:flex-row flex-col justify-center p-4 m-4'>
        <ContactCard
          icon='Email'
          linkType={ContactLinkType.Email}
          text='info@nilssonlaw.se'
          header='E-postadress'
        ></ContactCard>
        <ContactCard
          icon='Phone'
          linkType={ContactLinkType.Phone}
          text='0520-48 00 01'
          header='Telefon'
        ></ContactCard>
      </div>
    </div>
  );
};

export default Contact;
