import { Link } from 'react-router-dom';
import Icon from './Icons/Icon';

type CenterTextSplashProps = {
  className?: string;
  header?: string;
  topTextBlock?: string;
  bottomTextBlock?: string;
  link?: string;
  linkText?: string;
};

const CenterTextSplash: React.FC<CenterTextSplashProps> = ({
  header,
  className,
  topTextBlock,
  bottomTextBlock,
  link,
  linkText,
}) => {
  return (
    <div className={className}>
      <div className='flex items-center flex-col max-w-screen-md mx-8'>
        {topTextBlock && (
          <div className='flex items-center mb-8'>{topTextBlock}</div>
        )}
        <div className='text-5xl '>{header}</div>
        {link && (
          <div className='flex justify-center md:mt-14 my-6'>
            <Link
              to={link}
              className='flex items-center hover:bg-opacity-30 hover:bg-gray-400 pl-3 p-1 rounded-sm'
            >
              <div className='flex items-center'>{linkText}</div>
              <div className='flex items-center pt-0.5'>
                <Icon iconClassName='h-5 w-5' iconType='ChevronRight' />
              </div>
            </Link>
          </div>
        )}
        {bottomTextBlock && (
          <div className='flex items-center mb-8'>{bottomTextBlock}</div>
        )}
      </div>
    </div>
  );
};

export default CenterTextSplash;
