import { MouseEventHandler, useState } from 'react';
import SlideDrawer from './slideDrawer/slideDrawer';
import BackDrop from './slideDrawer/backDrop';
import Icon from './Icons/Icon';
import logonilsson from '../assets/images/logonilsson.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const backdropClickHandler: MouseEventHandler = (event) => {
    event.preventDefault();
    setDrawerOpen(false);
  };

  let backdrop;
  if (drawerOpen) {
    backdrop = <BackDrop backDropClickHandler={backdropClickHandler} />;
  }

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div className='h-16'>
      <div className='flex flex-grow bg-gray-50 h-16 items-center pl-8 pr-2 fixed w-screen shadow-2xl'>
        <div className='flex flex-grow'>
          <Link to='/'>
            <img src={logonilsson} alt='Nilsson Advokater' className='h-8' />
          </Link>
        </div>
        <div
          className='w-8 h-8 mx-8 hover:bg-gray-100 cursor-pointer justify-end'
          onClick={() => setDrawerOpen(true)}
        >
          <Icon iconType='MenuAlt2' iconClassName='h-full w-full'></Icon>
        </div>
        <SlideDrawer show={drawerOpen} closeHandler={closeDrawer} />
        {backdrop}
      </div>
    </div>
  );
};

export default Header;
