import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAYyMco5Weatxe45WFZZsPmQdRUaH-Vi5U',
  authDomain: 'jnl-law.firebaseapp.com',
  projectId: 'jnl-law',
  storageBucket: 'jnl-law.appspot.com',
  messagingSenderId: '558289549065',
  appId: '1:558289549065:web:962533688b5b15a994c5d8',
  measurementId: 'G-LWFETHJJ97',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default db;
