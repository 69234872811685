import React from 'react';
import { MouseEventHandler } from 'react';

type BackDropProps = {
  backDropClickHandler: MouseEventHandler;
};

const BackDrop: React.FC<BackDropProps> = ({ backDropClickHandler }) => {
  return (
    <div
      className='fixed w-full h-full bg-black opacity-30 z-48 top-0 right-0'
      onClick={backDropClickHandler}
    ></div>
  );
};

export default BackDrop;
