import { NavLink } from 'react-router-dom';
import Icon from '../Icons/Icon';

type SlideDrawerProps = {
  show: boolean;
  closeHandler: () => void;
};

const SlideDrawer: React.FC<SlideDrawerProps> = ({ show, closeHandler }) => {
  let drawerClasses = `fixed top-0 h-screen bg-white w-5/6 sm:w-2/6 w-0 z-50 right-0 shadow-lg transition-transform transform ease-in-out duration-300 ${
    show ? 'translate-x-0' : 'translate-x-full'
  }`;

  return (
    <div className={drawerClasses}>
      <div className='' onClick={closeHandler}>
        <Icon
          iconType='Close'
          iconClassName='h-8 w-8 right-2 mt-4 mr-4 fixed cursor-pointer transform hover:rotate-45 '
        ></Icon>
      </div>
      <div className='flex flex-col mt-8 mx-8'>
        <NavLink
          className='p-2 hover:bg-gray-50'
          to='/'
          activeClassName='font-semibold'
          exact
          onClick={closeHandler}
        >
          Hem
        </NavLink>
        <NavLink
          className='p-2 hover:bg-gray-50'
          to='/medarbetare'
          activeClassName='font-semibold'
          onClick={closeHandler}
        >
          Medarbetare
        </NavLink>
        <NavLink
          className='p-2 hover:bg-gray-50'
          to='/kontakt'
          activeClassName='font-semibold'
          onClick={closeHandler}
        >
          Kontakta oss
        </NavLink>
        <NavLink
          className='p-2 hover:bg-gray-50'
          to='/hitta-hit'
          activeClassName='font-semibold'
          onClick={closeHandler}
        >
          Hitta hit
        </NavLink>
      </div>
    </div>
  );
};

export default SlideDrawer;
