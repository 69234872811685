const Footer = () => {

  const currentYear = ()=>{
    return new Date().getFullYear();
  };

  return (
    <div className='w-full text-xs text-gray-400 py-2 bg-gray-50 flex flex-col justify-center  items-center'>
      <a
        href='/Information-enligt-dataskyddsforordningen-v-2022.pdf'
        download
        className='hover:text-gray-500'
      >
        Information om behandling av personuppgifter
      </a>
      <a
        href='https://www.advokatsamfundet.se/konsumenttvistnamnden'
        target='_blank'
        rel='noreferrer'
        className='hover:text-gray-500'
      >
        Information om Advokatsamfundets konsumenttvistnämnd
      </a>

      <a
        href='/Allmanna-villkor-v-2022.pdf'
        download
        className='hover:text-gray-500'
      >
        Allmänna villkor för advokattjänster
      </a>
      <p className=''>Copyright © {currentYear()} Nilsson Advokater Västsverige AB</p>
    </div>
  );
};

export default Footer;
